//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
export default {
  name: "RegistrationSuccessful",
  components: {
    LandingPagePopUp
  },
  methods: {
    onContinue() {
      this.$router.push({
        name: "select-role"
      });
    }
  },
  computed: {
    text() {
      if (this.$route.params.flag === "changed") return "Your password is successfully changed.";
      return "Your account has been successfully created.";
    }
  }
};